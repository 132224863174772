import React from "react";
import H1 from "../../components/h1";
import SEO from "../../components/seo";
import Par from "../../components/Par";

// Download link - Bowel Preparation - http://www.fbrennangastro.com.au/downloads/gesa_bowel_prep.pdf

const title = "Bowel Preparation";
const Services = () => {
	return (
		<div>
			<SEO
				title={title}
				keywords={[
					title,
					`Gastroenterology services`,
					`Endoscopy services`,
					`Gastroenterologist`,
					`Hepatologist`,
					`Dr Frank`,
					`Dr Brennan`,
					`Dr Spiro`,
					`Dr Raftopoulos`,
					`Dr Ian Yusoff`,
					`Dr Dev Segarajasingam`,
					`Dr Sam Rao`,
					`Dr Duy Pham`,
					`Dr Claire Gordon`,
				]}
				description={title}
			/>
			<H1 text={title} />
			<Par>
				For patients undergoing a colonoscopy or certain types of surgery it is essential that the colon (bowel) be
				thoroughly cleaned and emptied of all solid matter.
			</Par>
			<Par />
			<Par>Cleansing the colon is called bowel preparation.</Par>
			<Par />
			<Par>
				The goal of bowel preparation is to empty and clean the bowel for a diagnostic procedure (such as a colonoscopy)
				or for surgery (such as removal of polyps, cancer or narrowing of the intestinal diameter).
			</Par>
			<Par />
			<Par>
				A good bowel preparation allows the doctor to clearly see the lining of the bowel and thoroughly examine the
				colon. The procedure is then more likely to be accurate and complete.
			</Par>
			<Par />
			<Par>
				If the bowel is not completely clean and empty the procedure may need to be postponed or repeated. Potential
				complications may also occur or potential cancer lesions could be missed.
			</Par>
		</div>
	);
};

export default Services;
